import React, { useState, useEffect } from "react";
import { baseURL } from "../../../../Apis/Api";
import { FaPlusCircle, FaTrashAlt, FaImage } from "react-icons/fa"; // Import icons

const EditProductModal = ({
  product,
  isEditing,
  isOpen,
  onClose,
  categories,
  subCategories,
  onSave,

}) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    fakePrice: "",
    discountedPrice: "",
    wholesaleCode: "",
    category: "",
    subcategory: "",
    sizes: [],
    showProductinSite: false,
    isFlashSale: false,
    images: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen && product) {
      setFormData({
        name: product.name || "",
        description: product.description || "",
        price: product.price || "",
        fakePrice: product.fakePrice || "",
        discountedPrice: product.discountedPrice || "",
        wholesaleCode: product.wholesaleCode || "",
        category: product.category || "",
        subcategory: product.subcategory || "",
        sizes: product.sizes || [{ size: "", colors: [{ color: "", quantity: 0 }] }],
        showProductinSite: product.showProductinSite || false,
        isFlashSale: product.isFlashSale || false,
        images: product.images || [],
      });
    }
  }, [product, isOpen]);

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSizeColorChange = (sizeIndex, colorIndex, value, field) => {
    const updatedSizes = [...formData.sizes];

    if (field === "size" && updatedSizes[sizeIndex]) {
      updatedSizes[sizeIndex].size = value;
    } else if (
      field !== "size" &&
      updatedSizes[sizeIndex]?.colors[colorIndex]
    ) {
      updatedSizes[sizeIndex].colors[colorIndex][field] = value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      sizes: updatedSizes,
    }));
  };




  const handleAddSize = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sizes: [
        ...prevFormData.sizes,
        { size: "", colors: [{ color: "", quantity: 0 }] },
      ],
    }));
  };

  const handleAddColorToSize = (sizeIndex) => {
    const updatedSizes = [...formData.sizes];
    updatedSizes[sizeIndex].colors.push({ color: "", quantity: 0 });
    setFormData((prevFormData) => ({
      ...prevFormData,
      sizes: updatedSizes,
    }));
  };


  const handleRemoveColorFromSize = (sizeIndex, colorIndex) => {
    const updatedSizes = [...formData.sizes];
    updatedSizes[sizeIndex].colors = updatedSizes[sizeIndex].colors.filter(
      (_, idx) => idx !== colorIndex
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      sizes: updatedSizes,
    }));
  };

  const handleRemoveSize = (sizeIndex) => {
    const updatedSizes = formData.sizes.filter((_, idx) => idx !== sizeIndex);
    setFormData((prevFormData) => ({
      ...prevFormData,
      sizes: updatedSizes,
    }));
  };


  if (!isOpen) return null;

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    console.log('files: ', files);

    const imgs =[]

    files.forEach((file) => {

      imgs.push(file)

    });
    console.log('imgs: ', imgs);

    setFormData((prevFormData) => ({
      ...prevFormData,
      images: [...prevFormData.images, ...imgs],
    }));
  };

  
  const handleSave = async () => {
    setIsLoading(true);

    console.log('formData.images: ', formData.images);
    // const newImageFiles = formData.images.filter((image) => image.file).map(image=> image.file)
    const newImageFiles = formData.images.filter(el=> typeof el !== 'string')
    const existingImageURLs = formData.images.filter((el) => typeof el === 'string');



    const fd = new FormData();
    fd.append('name', formData.name);
    fd.append('description', formData.description);
    fd.append('sku', formData.sku);
    fd.append('price', formData.price);
    fd.append('fakePrice', formData.fakePrice);
    fd.append('discountedPrice', formData.discountedPrice);
    fd.append('wholesaleCode', formData.wholesaleCode);
    fd.append('category', formData.category);
    fd.append('subcategory', formData.subcategory);
    fd.append('sizes', JSON.stringify(formData.sizes));
    fd.append("showProductinSite", formData.showProductinSite); // Corrected this line
  fd.append("isFlashSale", formData.isFlashSale); // Ensure consistency
    fd.append('images', JSON.stringify(existingImageURLs));

    console.log('newImageFiles: ', newImageFiles);
    newImageFiles.forEach(image=>{
      console.log('image: ', typeof image);
      fd.append('images', image);
    })

    console.log('fd: ', {...fd});
    await onSave(fd);
    setIsLoading(false);
    onClose();
  };




  const handleDeleteImage = (indexToDelete) => {
    console.log('indexToDelete: ', indexToDelete);
    setFormData((prevFormData) => {
      const updatedImages = prevFormData.images.filter((_, index) => index !== indexToDelete);
      console.log('updatedImages: ', updatedImages);
      return {
        ...prevFormData,
        images: updatedImages,
      };
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex lg:items-center pt-2 justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full md:w-3/4 lg:w-2/3 xl:w-3/5 p-6 overflow-y-auto max-h-[90vh]">
        <h2 className="text-2xl font-bold mb-4 text-start bg-red-500  text-white p-2">
          {isEditing ? "Edit Product" : "View Product"}
        </h2>

        <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <label className="block">
            <span className="font-semibold">Product Name:</span>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled={!isEditing}
              placeholder="Enter product name"
            />
          </label>

          <label className="block">
            <span className="font-semibold">Description:</span>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled={!isEditing}
              placeholder="Enter product description"
            />
          </label>

          <label className="block">
            <span className="font-semibold">Actual Price:</span>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled={!isEditing}
              placeholder="Enter actual price"
            />
          </label>

          <label className="block">
            <span className="font-semibold">Fake Price:</span>
            <input
              type="number"
              name="fakePrice"
              value={formData.fakePrice}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled={!isEditing}
              placeholder="Enter fake price"
            />
          </label>

          <label className="block">
            <span className="font-semibold">Discounted Price:</span>
            <input
              type="number"
              name="discountedPrice"
              value={formData.discountedPrice}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled={!isEditing}
              placeholder="Enter discounted price"
            />
          </label>

          <label className="block">
            <span className="font-semibold">Wholesale Code:</span>
            <input
              type="text"
              name="wholesaleCode"
              value={formData.wholesaleCode}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled={!isEditing}
              placeholder="Enter wholesale code"
            />
          </label>

          <label className="block">
            <span className="font-semibold">Category:</span>
            <select
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded px-4 py-3"
              disabled={!isEditing}
            >
              <option value="">Select Category</option>
              {categories.map((cat) => (
                <option key={cat._id} value={cat._id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </label>


          <label className="block">
            <span className="font-semibold">Subcategory:</span>
            <select
              name="subcategory"
              value={formData.subcategory}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded px-4 py-3"
              disabled={!isEditing}
            >
              <option value="">Select Subcategory</option>
              {subCategories.map((sub) => (
                <option key={sub._id} value={sub._id}>
                  {sub.name}
                </option>
              ))}
            </select>
          </label>

          {formData.sizes.map((size, sizeIndex) => (
            <div key={sizeIndex} className="mb-6 p-4 border border-gray-300 rounded-lg">
              <h3 className="text-lg font-semibold">
                Size:
                <input
                  type="text"
                  value={size.size}
                  onChange={(e) =>
                    handleSizeColorChange(sizeIndex, null, e.target.value, "size")
                  }
                  className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2"
                  disabled={!isEditing}
                />

              </h3>
              {isEditing && (
                <button
                  type="button"
                  onClick={() => handleRemoveSize(sizeIndex)}
                  className="text-red-500 mt-2 flex items-center gap-2"
                >
                  <FaTrashAlt /> Remove Size
                </button>
              )}
              {size.colors.map((color, colorIndex) => (
                <div key={colorIndex} className="flex gap-6 mb-4 items-center">
                  <div className="w-1/2">
                    <label className="block font-semibold">Color</label>
                    <input
                      type="text"
                      value={color.color}
                      onChange={(e) =>
                        handleSizeColorChange(sizeIndex, colorIndex, e.target.value, "color")
                      }
                      className="border rounded-md px-4 py-2 w-full"
                      disabled={!isEditing}
                      placeholder="Enter color"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="block font-semibold">Quantity</label>
                    <input
                      type="number"
                      value={color.quantity}
                      onChange={(e) =>
                        handleSizeColorChange(sizeIndex, colorIndex, e.target.value, "quantity")
                      }
                      className="border rounded-md px-4 py-2 w-full"
                      disabled={!isEditing}
                      placeholder="Enter quantity"
                    />
                    {isEditing && (
                      <button
                        type="button"
                        onClick={() => handleRemoveColorFromSize(sizeIndex, colorIndex)}
                        className="text-red-500 mt-2 flex items-center gap-2"
                      >
                        <FaTrashAlt /> Remove Color
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {isEditing && (
                <button
                  type="button"
                  onClick={() => handleAddColorToSize(sizeIndex)}
                  className="text-blue-500 mt-2 flex items-center gap-2"
                >
                  <FaPlusCircle /> Add Color
                </button>
              )}
            </div>
          ))}

          {isEditing && (
            <button
              type="button"
              onClick={handleAddSize}
              className="text-blue-500 mt-4 flex items-center gap-2"
            >
              <FaPlusCircle /> Add Size
            </button>
          )}
          <div>
            {/* Flash Sale Checkbox */}
            <label className="block">
              <span className="font-semibold">Flash Sale:</span>
              <input
                type="checkbox"
                name="isFlashSale" 
                checked={formData.isFlashSale}
                onChange={handleInputChange} 
                className="mt-1"
              />
            </label>

            {/* Show Product on Site Checkbox */}
            <label className="block">
              <span className="font-semibold">Show Product on Site:</span>
              <input
                type="checkbox"
                name="showProductinSite" 
                checked={formData.showProductinSite}
                onChange={handleInputChange} 
                disabled={!isEditing} 
                className="mt-1"
              />
            </label>
          </div>


          {/* Image Upload */}
          <div className="mb-6">
  <h3 className="font-semibold">Images</h3>
  <div className="grid grid-cols-3 gap-4">
    {formData.images.map((image, index) => {
      const isOldImage = typeof image === "string";
      return (
        <div key={index} className="relative">
          <img
            src={
              isOldImage
                ? `${baseURL}/${image}` // Old image URL
                : URL.createObjectURL(image) // New image file
            }
            alt={`Product Image ${index + 1}`}
            className="w-full h-24 object-cover rounded"
          />
          <span
            className={`absolute top-2 left-2 px-2 py-1 text-xs font-bold rounded ${
              isOldImage ? "bg-red-600 text-white" : "bg-green-600 text-white"
            }`}
          >
            {isOldImage ? "Old Image" : "New Image"}
          </span>
          {isEditing && (
            <button
              onClick={() => handleDeleteImage(index)}
              className="absolute top-1 right-1 bg-red-600 text-white rounded-full px-2 py-1 text-sm"
            >
              ×
            </button>
          )}
        </div>
      );
    })}
  </div>
  {isEditing && (
    <>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageUpload}
        className="mt-3 w-full text-gray-500 font-medium text-base bg-gray-100 file:cursor-pointer file:border-0 file:py-2.5 file:px-4 file:mr-4 file:bg-gray-800 file:hover:bg-gray-700 file:text-white rounded"
      />
      <p className="mt-1 text-sm text-gray-500">
        Select images to upload (multiple files allowed)
      </p>
    </>
  )}
</div>

        </form>

        <div className="flex justify-end mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 mr-2 bg-gray-300 rounded shadow hover:bg-gray-400"
            disabled={isLoading}
          >
            Close
          </button>
          {isEditing && (
            <button
              onClick={handleSave}
              className={`px-4 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700 ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save Changes"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditProductModal;
